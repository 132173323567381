footer {
  background: var(--color-primary);
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
}

a:hover {
  --bs-link-hover-color: var(--color-text);
  text-decoration: none;
}

.logo {
  width: 120px;
  margin: 0 auto;
  padding-bottom: 2rem;
}

footer a {
  color: var(--color-bg);
}

.footer__logo {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

.footer__socials a {
  background: var(--color-blue-2);
  color: var(--color-white);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid transparent;
}

.footer__socials a:hover {
  background: transparent;
  color: var(--color-bg);
  border-color: var(--color-bg);
}

.footer__copyright {
  margin-bottom: 4rem;
  color: var(--color-bg);
}

.footer-headline {
  text-decoration: underline;
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .footer__socials {
    margin-bottom: 2.6rem;
  }
}
