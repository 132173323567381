#contact {
  background-color: var(--color-bg);
}
.container.contact__container {
  width: 58%;
  display: grid;
  gap: 12%;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__option {
  padding: 1.2rem;
  border: 1px solid var(--color-bg-variant);
  border-radius: 1.2rem;
  text-align: center;
  transition: var(--transition);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option h5 {
  color: var(--color-light);
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

.contact__options .contact_link:focus {
  --bs-link-color: #fff;
}

a:hover {
  --bs-link-color: var(--color-white);
  text-decoration: none !important;
}

/* ================= FORM ================== */
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}
