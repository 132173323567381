#creation {
  background-color: var(--color-white);
}

a {
  --bs-link-color: var(--color-bg-variant);
  text-decoration: underline;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
}
