.email-list-window{
  height: 800px;
  overflow-y: scroll;
}

.email-list-item{
  padding-left: 20px;
  cursor: pointer;
}

.email-list-sender, .email-list-subject, .email-list-to, .email-list-short-description{
  word-wrap: break-word;
}

.pre-content{
  white-space: pre-wrap;
}

.selected-item {
  background-color: #0e172a !important;;
  color: white;
}

.selected-item .email-color-blue{
  background-color: #0e172a !important;;
  color: white;
}

.selected-item .email-color-sender-grey{
  background-color: #0e172a !important;;
  color: white;
}

.email-list-item:hover{
  background-color: #0e172a !important;
}

.email-list-item:hover .email-color-blue{
  color: white;
}

.email-list-item:hover .email-color-sender-grey{
  color: white;
}

.email-color-grey{
  color: #64748b;
}

.email-color-sender-grey{
  color: #64748b;
}

.fw500{
  font-weight: 500;
}

.email-color-blue{
  color: #0e172a;
}

.email-list-sender{
  font-size: 1rem;
}

.email-list-short-description{
  line-height: 1.2;
  color: #a0afbf;
}

.attachment{
  background-color: unset;
}