.blog-page-headline {
  color: var(--color-blue-2);
}

.blog-item {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 40px 30px !important;
  margin-bottom: 40px;
}
